<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Latest users</h4>
            <h6 class="card-subtitle">Persons granted access to the secure control panel</h6>
            <div class="actions">
                <router-link to="/users/add">
                    <i class="zmdi zmdi-plus actions__item"></i>
                </router-link>
            </div>
        </div>
        <div v-if="isLoading">Loading...</div>
        <div v-else-if="users.length > 0" class="listview listview--bordered">
            <div class="listview__item" v-for="(user, i) in users" :key="i">
                <img class="listview__img_users" :src="user.photo" v-if="user.photo != ''">
                <i class="avatar-char bg-light-blue" v-else></i>
                <div class="listview__content">
                    <div class="listview__heading">{{ user.fullname }}</div>
                    <p>{{ user.email }}</p>
                </div>
                <div class="actions listview__actions">
                    <!-- <i class="actions__item zmdi zmdi-eye"></i> -->
                </div>
            </div>
        </div>
        <div class="notfound" v-else>No users found</div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            users: [],
            isLoading: false,
        }
    },
    created(){
        this.load()
    },
    methods: {
        async load(){
            try {
                this.isLoading = true
                const snap = await this.$db.collection("users").orderBy("createdAt", "desc").limit(5).get()
                snap.forEach(doc => {
                    this.users.push({ id: doc.id, ...doc.data() })
                })
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
            }
        },
        view(id){
            this.$router.push("/users/n/" + id)
        },
    }
}
</script>


<style scoped>
.listview__img_users {
    width: 40.50px;
    height: 40.50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1.2rem;
}
.listview__heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
</style>